import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from 'styled-components';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../layouts";
import Seo from "../components/seo";
import {
	BreadCrumb,
	Section,
	SectionBgWrap,
	SectionPageTitle,
	SectionTitle,
	SectionDescription,
	MarkdownContent
} from '../components/Section'
import GrayWoodSection from '../components/Section/GrayWoodSection'
import ColorOptionsForm from '../components/Forms/ColorOptionsForm';
import CtaSection from '../sections/CtaSection';
import GallerySection from '../sections/Category/GallerySection';
import ColorOptionArrow from '../components/Icons/ColorOptionArrow';

const ColorOptionsWrap = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 13px;
	
	.arrow-wrap {
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translateX(-50%);
	}

	@media(max-width: 991px) {
		grid-template-columns: repeat(1, 1fr);

		.arrow-wrap {
			top: 45%;
			left:0;
			transform: rotate(45deg);

			svg {
				width: 180px;
			}
		}
	}
	@media(max-width: 768px) {
		.arrow-wrap {
			top: 50%;
			svg {
				width: 150px;
			}
		}
	}
	@media(max-width: 420px) {
		.arrow-wrap {
			top: 47%;
			svg {
				width: 100px;
			}
		}
	}

`

const ColorPanel = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 50px 30px 28px 50px;
	background: #ffffff;
	box-shadow: 0 20px 60px rgba(0, 0, 0, .2);
	@media(max-width: 480px) {
		padding: 15px 10px;
	}
`
const ColorItem = styled.div`
	width: calc(16.6% - 20px);
	margin-right: 20px;
	margin-bottom: 22px;

	.image-wrap {
		box-shadow: ${props => props.boxShadow};
		.gatsby-image-wrapper {
			width: 100%;
		}
	}

	p {
		font-size: 14px;
		font-family: "Roboto";
		line-height: 24px;
		text-align: center;
		margin-bottom: 0;
	}
	@media(max-width: 768px) {
		width: calc(25% - 20px);
		margin: 10px;
	}

`

const ColorBuildingImageWrap = styled.div`
	border: 3px solid #ffffff;
	box-shadow: 0 20px 60px rgba(0, 0, 0, .2);
	height: 400px;
	display: ${props => props.display};

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const ColorOptions = ({ data, location }) => {
	const [currentColor, setColor] = useState("Blue");
	const pageData = data.contentfulColorOptionsPage;
	const gallerySectionData = {
		title: pageData.gallerySectionTitle,
		description: pageData.gallerySectionDescription.gallerySectionDescription,
		products: data.allContentfulProduct.edges
	}

	return(
		<Layout footerCta location={location}>
			<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Color Options</span>
				</div>
			</BreadCrumb>
			<Section pt="50px" pb="70px">
				<SectionBgWrap>
					<StaticImage
						src="../images/color-options-bg.png"
		        placeholder="blurred"
		        formats={["AUTO", "WEBP", "AVIF"]}
		        alt="color-options"
		      />
				</SectionBgWrap>
				<div className="container">
					<SectionPageTitle color="#ffffff">{pageData.heroTitle}</SectionPageTitle>
					<SectionDescription color="#ffffff" maxWidth="960px">
						<div dangerouslySetInnerHTML={{__html: pageData.heroDescription.childMarkdownRemark.html}} />
					</SectionDescription>
					<ColorOptionsWrap>
						<ColorPanel>
							{
								data.allContentfulColor.edges.map((color) => {
									return(
										<ColorItem
											key={color.node.name}
											className="color-item"
											onClick={()=>setColor(color.node.name)}
											boxShadow={currentColor === color.node.name ? "0 10px 20px rgba(0, 0, 0, .2);" : "none"}
										>
											<div className="image-wrap">
												<GatsbyImage image={getImage(color.node.icon.gatsbyImageData)} alt={color.node.name} />
											</div>
											<p>{color.node.name}</p>
										</ColorItem>
									)
								})
							}
						</ColorPanel>
						<div>
							{
								data.allContentfulColor.edges.map((color) => {
									return(
										<ColorBuildingImageWrap display={currentColor === color.node.name ? "block" : "none"}>
											<GatsbyImage image={getImage(color.node.image.gatsbyImageData)} alt={color.node.name} />
										</ColorBuildingImageWrap>
									)
								})
							}
						</div>
						<div className="arrow-wrap">
							<ColorOptionArrow />
						</div>
					</ColorOptionsWrap>
				</div>
			</Section>
			<GrayWoodSection>
				<div className="container">
					<SectionTitle>{pageData.formSectionTitle}</SectionTitle>
					<SectionDescription maxWidth="992px">
						<div dangerouslySetInnerHTML={{__html: pageData.formSectionDescription.childMarkdownRemark.html}} />
					</SectionDescription>
					<ColorOptionsForm location={location} />
				</div>
			</GrayWoodSection>
			<GallerySection data={gallerySectionData} />
			<CtaSection mb="0" />
      <GrayWoodSection mb="50px">
				<div className="container">
					<MarkdownContent
        		dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}}
        		maxWidth="950px"
        	/>
				</div>
			</GrayWoodSection>
		</Layout>
	)
}

export default ColorOptions

export const query = graphql`
  query ColorOptionsPageQuery {
  	contentfulColorOptionsPage {
	    metaTitle
	    metaDescription
	    heroTitle
	    heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    formSectionTitle
	    formSectionDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    gallerySectionTitle
	    gallerySectionDescription {
	      gallerySectionDescription
	    }
	    content {
	    	childMarkdownRemark {
	        html
	      }
	    }
	  }
  	allContentfulColor {
  		edges {
  			node {
  				name
  				color {
  					gatsbyImageData(placeholder: BLURRED, quality: 50)
  				}
  				icon {
  					gatsbyImageData(placeholder: BLURRED, quality: 50)
  				}
  				image {
  					gatsbyImageData(placeholder: BLURRED, quality: 50)
  				}
  			}
  		}
  	}
  	allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
  }
`