import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Section, SectionTitle, SectionDescription, TextCenter } from "../../components/Section";
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import LineArrowRight from "../../components/Icons/LineArrowRight";

const GalleryWrap = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 49px;

	.item {
		margin-top: 30px;
		width: calc(50% - 15px);

		.gatsby-image-wrapper {
			width: 100%;
		}
	}

	@media(max-width: 768px) {
		flex-wrap: wrap;
		.item {
			width: 100%;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
		}
	}
`

const GallerySection = ({ data, products }) => (
	<Section pt="100px" pb="99px">
		<div className="container">
			<SectionTitle>{data.title}</SectionTitle>
			<SectionDescription maxWidth="960px">
				{data.description.childMarkdownRemark ? (<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />) : (
					<p>{data.description}</p>
				)}
			</SectionDescription>
			<GalleryWrap>
			{
				products?.map((item, i)=> {
					if (i < 2) {
						return(
							<div className="item" key={`gallery-product-${i}`}>
								<GatsbyImage image={getImage(item.image.gatsbyImageData)} alt={item.name} />
							</div>
						)
					} else {
						return true;
					}
				})
			}
			</GalleryWrap>
			<TextCenter>
				<Link to="/gallery">
					<PrimaryLinkButton text="Explore our All Products" icon={<LineArrowRight />} />
				</Link>
			</TextCenter>
		</div>
	</Section>
);

export default GallerySection;